@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,700');

$primary: #f37455;
$secondary: #b3cfb4;
/* SECONDARY COLOR */
$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

body,
html {
    height: 100%;
    /*margin: 0;
  padding: 0;
  overflow: auto;*/
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-family: 'Titillium Web', sans-serif;

}

.flash {
    display: none;
}

blockquote,
q {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        > li > a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0;
                display: inline-block;
            }

            &:active,
            &:focus {
                background: transparent;
                color: $blk;
                outline: 0;
            }

            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0;
}

.navbar-toggle {
    margin: 20px 15px 8px 0;
    border: 1px solid transparent;
    border-radius: 4px;
}
/** LOGIN FORM **/
@mixin btn {
    border: none;
    background: $primary;
    color: $wht;
    padding: 0.8em 2em;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 1em 0;
    transition: 0.7s ease all;

    &:hover {
      background: $primary;
      color: $wht;
        text-decoration: none;
        @media (max-width: 1024px) {
          background: $primary;
          color: $wht;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;

    .close {
        display: none;
    }

    .modal-content {
        color: $primary;

        h2 {
            text-align: center;
        }
    }

    input {
        border: 1px solid #777;
        text-align: center;
    }

    input#password,
    input#username {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }

    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;

        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0 0 3px $blk;
            border: none;
        }
    }

    input[type="submit"] {
        @include btn;
        display: block;
        width: 100%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-footer,
.modal-header {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0 !important;
}

input#username {
    margin-bottom: 20px;
}
/** END LOGIN FORM **/
// section-start
section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  h2 {
    text-transform: uppercase;
    font-size: 1.8em;
    text-align: center;
    @media(max-width: 1024px){
      font-size: 1.5em;
    }
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
  span{
    font-weight: bold;
  }
  p,h2{
    color: lighten($blk, 20%);
  }

}
.section-a {
  padding: 350px 0;
  background-image: url('../img/bg1.jpg');
  background-attachment: fixed;
  color: #fff;
  text-align: center;
  @media(max-width: 1024px){
    background-attachment: scroll;
  }
  @media(max-width: 991px){
    padding: 250px 0;
  }
  @media(max-width: 767px){
    padding: 150px 0;
  }
  @media(max-width: 600px){
    padding: 80px 0;
  }
  @media(max-width: 420px){
    padding: 40px 0;
  }
  h1 {
    font-size: 4em;
    @media(max-width: 767px){
        font-size: 2em;
    }
    @media(max-width: 600px){
        font-size: 1.5em;
    }
  }
}
.section-b{

  .col-md-4{
    padding: 0px;
  }
  h2{
    padding-left: 20px;
    text-transform: uppercase;
    text-align: center;
    color: $wht;
  }
  p{
    padding: 0px 20px 10px 20px;
    text-align: center;
    color: $wht;
  }
  background-color: $primary;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
  background-size: auto;
  background-repeat: repeat;
  padding: 100px 0;
  @media(max-width:991px){
    padding: 80px 0;
  }
  @media(max-width: 767px){
    padding: 40px 0;
  }
}
.section-c{
  padding: 350px 0;
  background-image: url('../img/bg2.jpg');
  background-attachment: fixed;
  text-align: center;
  @media(max-width: 1024px){
    background-attachment: scroll;
  }
  @media(max-width: 991px){
    padding: 250px 0;
  }
  @media(max-width: 767px){
    padding: 150px 0;
  }
  @media(max-width: 600px){
    padding: 80px 0;
  }
  @media(max-width: 400px){
    padding: 40px 0;
  }
  h2,p{
    color: $wht;
  }
  p{
    font-size: 1.5em;
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
}
.section-d{
  padding: 100px 0;
  @media(max-width:991px){
    padding: 80px 0;
  }
  @media(max-width: 767px){
    padding: 40px 0;
  }
  background-color: $secondary;
  h2,p {
    color: $wht;
    text-align: center;
  }
  p{
    font-size: 1.5em;
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
}

//sections ends
footer {
    padding: 50px 0 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;

    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }

        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }

        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }

    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#password,
input#username {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:active,
a:focus,
a:hover {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}
@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }

    .navbar-header a {
        float: left;
    }
}
@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }

    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}
